

/* Elimina el fondo azul al hacer clic en el encabezado del acordeón */
.accordion-button:focus, .accordion-button:not(.collapsed) {
    box-shadow: none; /* Elimina el borde/efecto de enfoque */
}

.accordion-button:focus {
    background-color: transparent; /* Elimina el fondo azul en el foco */
    color: inherit; /* Mantén el color del texto original */
}

.accordion-button:not(.collapsed) {
    background-color: transparent; /* Elimina el fondo azul cuando está expandido */
    color: inherit; /* Mantén el color del texto original */
    box-shadow: none; /* Elimina el borde/efecto de enfoque */
}

/* Alinea todos los textos a la izquierda dentro de los acordeones */
.accordion-body {
    text-align: left;
}


/* Botones transparentes con bordes y sombra al pasar el mouse */
.btn-transparent {
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.btn-transparent:hover {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
    background-color: rgba(0, 123, 255, 0.1);
    color: #0056b3;
}

/* Campos de fecha pequeños */
.form-control-date {
    width: 150px;
    display: inline-block;
}

.alert-small {
    font-size: 0.875rem; /* Ajusta el tamaño del texto aquí */
}

.btn-ai {
    background-color: #000000; /* Fondo negro */
    color: white; /* Texto blanco para contraste */
    border: none; /* Sin bordes */
    padding: 10px 20px; /* Espaciado */
    border-radius: 8px; /* Bordes redondeados */
    display: flex; /* Flexbox para alinear icono y texto */
    align-items: center; /* Centrado vertical del contenido */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Sombra suave */
    transition: background 0.3s ease, transform 0.3s ease; /* Transición suave */
}

.btn-ai:hover {
    background-color: #333333; /* Un gris oscuro para el hover */
    transform: translateY(-2px); /* Efecto de desplazamiento hacia arriba */
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3); /* Sombra aumentada */
}

.file-name-small {
    font-size: 0.85rem;
    word-wrap: break-word;
    max-width: 100%;
    overflow-wrap: break-word;
}

.btn-download {
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 1.0rem;
    color: #002751b3;
  }
.btn-download:hover {
    color: #0057b34f;
    text-decoration: none;
}  
  
.date-filters {
    display: flex;
    align-items: center;
  }

.date-filters .btn-download {
    margin-left: auto;
}

  /* Margen inferior para el último Accordion */
.mb-5 {
    margin-bottom: 3rem !important;
}
  
/* Estilos para el gráfico */
.chart-container canvas {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
}
/* data.css */
.bg-warning-custom {
    background-color: #eabc24 !important; /* Color amarillo claro similar a Bootstrap's bg-warning */
  }

  .hinweis-card {
    position: relative;
    background-color: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente */
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
  }
  
 
  
  .hinweis-card .card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000000; /* Color azul para el título */
  }
  
  .hinweis-card .card-text {
    font-size: 1rem;
    color: #333;
  }
  
  /* Opcional: Importar una fuente agradable desde Google Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');