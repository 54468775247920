.settings-container {
    padding: 20px 40px 20px 20px;
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    margin: 0; /* Elimina cualquier margen automático */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alinea el contenedor a la izquierda */
    justify-content: flex-start;
    height: 100%;
    box-sizing: border-box;
    text-align: left; /* Alinea todo el contenido dentro del contenedor a la izquierda */
}

.section {
    width: 100%;
    margin-bottom: 40px; /* Espacio entre secciones */
    text-align: left; /* Asegura que todo el contenido esté alineado a la izquierda */
}

.section h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    text-align: left; /* Asegura que el título esté alineado a la izquierda */
}

.form-group {
    width: 100%;
    margin-bottom: 15px;
    text-align: left; /* Alinea el contenido del grupo a la izquierda */
}

.form-group label {
    font-weight: 500;
    color: #333;
    font-size: 0.9rem;
    display: block;
    margin-bottom: 5px;
    text-align: left; /* Alinea las etiquetas a la izquierda */
}

.form-control,
.form-control-file {
    border-radius: 4px;
    border: 1px solid #ced4da;
    box-shadow: none;
    padding: 8px;
    font-size: 0.9rem;
    text-align: left; /* Asegura que el contenido esté alineado a la izquierda */
}

.form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px; /* Espacio entre botones */
    margin-top: 20px;
}

.btn-outline {
    font-size: 0.9rem;
    padding: 8px 15px;
    background-color: transparent; /* Fondo transparente */
    border: 1px solid #007bff; /* Borde azul */
    color: #007bff; /* Texto azul */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    cursor: pointer;
    transition: all 0.3s ease;
    align-self: flex-start; /* Alinea los botones a la izquierda */
    text-align: left; /* Asegura que el contenido del botón esté alineado a la izquierda */
}

.btn-outline:hover {
    background-color: #007bff; /* Fondo azul al hacer hover */
    color: #fff; /* Texto blanco al hacer hover */
}

.settings-container {
    padding: 20px;
}

.section {
    margin-bottom: 40px;
}

.info-card {
    background-color: #f8f9fa;
    padding: 40px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); /* Sombra con tono azul */
    margin-bottom: 40px;
}

.info-icon {
    font-size: 3rem;
    color: #000; /* Icono en color negro */
    margin-bottom: 20px;
}

.info-card h2 {
    text-align: center;
    margin-bottom: 20px;
}

.info-card p {
    text-align: justify;
}

.company-info .form-group {
    margin-bottom: 20px;
}

.company-info label {
    font-weight: bold;
}

.company-info .form-control {
    background-color: #e9ecef; /* Fondo gris para indicar que está deshabilitado */
    color: #495057;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .settings-container {
        padding: 10px 20px 10px 10px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .btn-outline {
        width: auto;
        margin-bottom: 10px;
    }
}
