/* Fondo general del dashboard */
.dashboard-container {
    position: relative;
    min-height: 100vh;
    background-image: url('../assets/blurbackground.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow-y: auto;
    padding: 20px;
}

/* Fondo fijo */
.background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.185);
    backdrop-filter: blur(10px);
    z-index: -1;
}

/* Grid para las tarjetas superiores */
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Tres columnas iguales */
    grid-gap: 20px; /* Espaciado entre tarjetas */
    width: 100%;
}

/* Sección adicional para el 100% de ancho */
.full-width-section {
    margin-top: 40px;
    display: block;
    width: 100%;
}

/* Ocultar el mapa */
.map-container {
    display: none;
}

/* Ajustar la tarjeta que contiene la barra de datos */
.data-card {
    min-height: auto;
    height: auto;
    padding: 20px;
}

/* Asegurar que la barra de datos no tenga márgenes adicionales */
.data-card .data-bar {
    margin-bottom: 0;
}

/* Barra de datos sobre la contaminación */
.data-bar {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.7);
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.data-item {
    display: flex;
    align-items: center;
}

.data-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.data-text {
    font-size: 1.2rem;
    color: #333;
}

/* Estilos generales para las tarjetas */
.card {
    position: relative;
    border: none;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    height: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.784); /* Transparencia para el efecto de cristal */
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(255, 255, 255, 0.82);
    background: rgba(255, 255, 255, 0.548);
}

.card-icon {
    width: 100px;
    height: auto;
    margin: 20px auto;
    display: block;
}

.card-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #000000;
    margin: 10px 0;
    text-align: center;
}

.card-subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: hsl(0, 0%, 0%);
    margin: 15px 0;
    text-align: left;
    padding-left: 10px;
}

.card-text {
    font-size: 1rem;
    color: #000000;
    margin: 15px 0;
    text-align: left;
    line-height: 1.4;
    padding-left: 10px;
}

/* Noticias sobre ESG */
.news-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-align: center;
}

.news-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
}

.news-content {
    padding: 10px;
    text-align: center;
}

.news-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
}

.news-description {
    font-size: 1rem;
    color: #333;
}

.news a {
    color: blue;
    text-decoration: underline;
}

.slick-dots {
    bottom: -30px;
}

.slick-prev,
.slick-next {
    color: #333;
}

.no-hover:hover {
    transform: none;
    box-shadow: none;
}

/* ----------- Estilos Responsivos ----------- */
@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .data-bar {
        flex-direction: column;
        align-items: flex-start;
    }

    .data-item {
        margin-bottom: 10px;
    }

    .data-text {
        font-size: 1rem;
    }

    .card-title {
        font-size: 1.5rem;
    }

    .card-subtitle {
        font-size: 1.3rem;
    }

    .card-text {
        font-size: 0.9rem;
    }

    .news-title {
        font-size: 1.1rem;
    }

    .news-description {
        font-size: 0.9rem;
    }

    .dashboard-container {
        padding: 15px;
    }

    .full-width-section {
        margin-top: 30px;
    }
}