/* Berichte.css */
.berichte-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
  }
  
  .info-card {
    background-color: #f8f9fa;
    padding: 50px; /* Aumenta el padding para agrandar la tarjeta */
    border-radius: 15px;
    max-width: 1000px; /* Incrementa el ancho máximo de la tarjeta */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); /* Sombra con tono azul */
  }
  
  .info-icon {
    font-size: 3rem;
    color: #000; /* Icono en color negro */
    margin-bottom: 20px;
  }
  
  .info-card h2 {
    text-align: center; /* Asegura que el título esté centrado */
  }
  
  .info-card p {
    text-align: justify;
  }