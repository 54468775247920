/* Documentation.css */

/* Estilos generales */
.documentation-container {
  overflow: hidden;
  font-family: Arial, sans-serif;
}

/* Navegación */
.navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.navigation button {
  background-color: #ffffff;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 0.9em;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.navigation button.active,
.navigation button:hover {
  background-color: #007bff;
  color: #fff;
}

.navigation button:focus {
  outline: none;
}

/* Contenido */
.content {
  overflow: hidden;
}

/* Estilos para las tarjetas */
.card-scroller {
  position: relative;
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scrollbar-width: none; /* Ocultar scroll en Firefox */
}

.card-scroller::-webkit-scrollbar {
  display: none; /* Ocultar scroll en Chrome, Safari y Opera */
}

.cards-wrapper {
  scroll-behavior: smooth;
}

.big-card {
  scroll-snap-align: start;
  position: relative;
  width: 98%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra horizontalmente */
  justify-content: center; /* Centra verticalmente */
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 10px 30px rgba(76, 74, 155, 0.1);
  text-align: center; /* Centra el texto */
}

.icon-container {
  margin-bottom: 20px;
}

.big-card h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
}

.card-content {
  max-width: 800px;
  width: 100%;
  text-align: center;
}

.card-content h4 {
  margin-top: 20px;
  font-size: 1.1em;
  color: #333;
  text-align: center;
}

.card-content p {
  font-size: 0.95em;
  color: #555;
  line-height: 1.5;
  text-align: center;
}

.card-content a {
  color: #1a0dab;
  text-decoration: none;
}

.card-content a:hover {
  text-decoration: underline;
}

/* Puntos de paginación */
.pagination-dots {
  position: fixed;
  top: 50%;
  right: 10px; /* Ajusta este valor para acercarlos al borde derecho */
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pagination-dots .dot {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 5px 0;
  transition: background-color 0.3s;
}

.pagination-dots .dot.active {
  background-color: #007bff;
}

/* Estilos para el contenido de About */
.about-content {
  text-align: center;
  padding: 50px 20px;
}

.about-content h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.about-content p {
  font-size: 1em;
  color: #555;
  margin: 10px 0;
}

/* Responsividad */
@media (max-width: 768px) {
  .big-card h3 {
    font-size: 1.4em;
  }

  .card-content h4 {
    font-size: 1em;
  }

  .card-content p {
    font-size: 0.9em;
    padding: 0 10px;
  }

  .navigation button {
    padding: 6px 12px;
    margin: 0 3px;
    font-size: 0.85em;
  }
  .logo-image {
    width: 30px; /* Ajusta el tamaño según tus preferencias */
    height: auto;
    margin-bottom: 20px;
  }
  .about-content h2 {
    text-align: left;
    font-size: 1.8em;
    margin-bottom: 20px;
  }
  
  .about-content p {
    text-align: left;
    font-size: 1em;
    color: #555;
    margin: 10px 0;
  }
}