/* Reset de estilos básicos */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Tipografía y colores uniformes */
body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

/* Contenedor principal */
.analyse-container {
  max-width: 100%;
  margin: 0;
  padding:0 ;
}

/* Encabezados */
h1, h2, h3, h4, h5, h6 {
  color: #0c001b;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.2;
}

h1 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

/* Texto */
p, label, .card-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Contenedor de contenido */
.content-wrapper {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Filtros de fecha */
.date-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.date-filters > div {
  margin-bottom: 10px;
}

/* Botones */
button, .btn {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  padding: 8px 16px;
}

.btn-ai, .btn-upload {
  background-color: #0c001b;
  color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-ai:hover, .btn-upload:hover {
  background-color: #000000;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.btn-secondary {
  background-color: #ffffff;
  color: #0c001b;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-secondary:hover {
  background-color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-action {
  background-color: #ffffff;
  color: #0c001b;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-action:hover {
  background-color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Dropdown */
.dropdown-toggle {
  background-color: #357EDD;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-toggle:hover {
  background-color: #2b6cb0;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.dropdown-menu {
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Tabla */
.analysis-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.analysis-table thead th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #0c001b;
  text-align: center;
  padding: 10px;
}

.analysis-table th, .analysis-table td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.analysis-table tbody tr:hover {
  background-color: #f9f9f9;
}

.bemerkungen-column {
  max-width: 300px;
  width: 30%;
}

/* Drag-and-Drop */
.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #cccccc;
  transition: border 0.3s ease;
  margin-bottom: 20px;
}

.dropzone.active {
  border-color: #2196f3;
  color: #2196f3;
}

/* Tarjeta especial */
.special-card {
  background: linear-gradient(270deg, #120154, #2a7c85, #0f205e);
  background-size: 400% 400%;
  animation: gradientAnimation 26s ease infinite;
  color: #fff;
  border: none;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(14, 58, 116, 0.364);
  margin-bottom: 10px;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.special-card .card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-icon {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 10px;
}

.special-card .card-title {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.special-card .card-text {
  color: #f0f0f0;
  font-size: 1rem;
}

/* Responsividad */
@media (max-width: 768px) {
  .date-filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .date-filters > div {
    margin-right: 0;
    width: 100%;
  }

  .analysis-table th, .analysis-table td {
    font-size: 0.9rem;
  }

  .content-wrapper {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .analysis-table th, .analysis-table td {
    font-size: 0.8rem;
  }

  .btn, .dropdown-toggle {
    font-size: 0.9rem;
    padding: 6px 12px;
  }

  .special-card .card-title {
    font-size: 1.2rem;
  }

  .special-card .card-text {
    font-size: 0.9rem;
  }

    /* Input-Feld verstecken */
    #fileInput {
      display: none;
    }
  
    /* Drag-and-Drop-Bereich verstecken */
    .dropzone {
      display: none;
    }
  
    /* Die zwei Buttons verstecken */
    .btn-upload,
    .btn-ai {
      display: none;
    }
    .btn-ai .btn .btn-primary{
      display: none;
    }
  
    /* Abschnitt "Dateien, die auf Analyse warten:" verstecken */
    .mt-4 {
      display: none;
    }
}