/* DataContent.css */


/* Estilos para las tarjetas existentes */
.card {
   
    box-shadow: 0 2px 4px rgba(15, 60, 115, 0.35); /* Añade sombra a las tarjetas existentes */


}

.card:hover {
    cursor: pointer;
    background-color: #f8f9fa;
}

.card .card-body {
    padding: 15px;
}
