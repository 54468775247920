.App {
  text-align: center;
}

.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 2px -2px gray;
  height: 63px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 120;
}

.logo {
  height: 30px;
}

/* Sidebar verstecken und oben halten, mit hohem z-index */
.sidebar {
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 200; /* Höherer z-index */
  padding-top: 0px;
  background-color: #ffffff;
  transition: transform 0.5s ease-in-out;
  width: 228px;
  box-shadow: 0 5px 10px -3px rgba(0, 85, 255, 0.682);
  transform: translateX(-100%); /* Versteckt die Sidebar standardmäßig */
}

/* Sidebar anzeigen, wenn geöffnet */
.sidebar-open .sidebar {
  transform: translateX(0);
  animation: slideIn 0.5s ease-out forwards;
}

/* Sidebar verstecken, wenn geschlossen */
.sidebar-closed .sidebar {
  transform: translateX(-100%);
  animation: slideOut 0.5s ease-out forwards;
}

.content-area {
  padding-top: 60px;
  transition: margin-left 0.5s ease-in-out;
  width: 100%;
  max-width: 100%;
  margin-left: 0; /* Standardmäßig kein Margin */
  box-sizing: border-box;
}

/* Inhalt anpassen, wenn Sidebar geöffnet ist */
.sidebar-open .content-area {
  margin-left: 228px;
  width: calc(100% - 228px);
}

.with-sidebar {
  margin-left: 228px;
  width: calc(100% - 228px);
}

.without-sidebar {
  margin-left: 0;
  width: 100%;
}

/* Schaltfläche zum Umschalten der Sidebar */
.sidebar-toggle-btn {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 210;
  background-color: #00000051;
  color: white;
  border: none;
  border-radius: 100%;
  width: 29px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-toggle-btn:hover {
  background-color: #c4c4c4bf;
}

.sidebar-toggle-btn i {
  transition: transform 0.5s ease;
}

.sidebar-open .sidebar-toggle-btn i {
  transform: rotate(0deg);
}

.sidebar-closed .sidebar-toggle-btn i {
  transform: rotate(-360deg);
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 767.98px) {
  .logo {
    height: 40px;
  }

  .with-sidebar {
    margin-left: 0;
  }

  .sidebar {
    width: 100%;
    top: 60px;
    height: calc(100vh - 60px);
    z-index: 200;
  }

  .content-area {
    margin-top: 0px;
    width: 100%;
  }

  /* Sidebar standardmäßig verstecken auf mobilen Geräten */
  .sidebar {
    transform: translateX(-100%);
  }

  /* Sidebar anzeigen, wenn geöffnet auf mobilen Geräten */
  .sidebar-open .sidebar {
    transform: translateX(0);
  }
}

/* Navigation in der Sidebar */
.sidebar .nav-link {
  padding: 8px 11px;
  text-align: left;
  display: block;
  font-size: 0.9rem;
  color: #333;
  text-decoration: none;
  border-radius: 5px;
  margin: 5px 10px;
}

.sidebar .nav-link i {
  margin-right: 10px;
}

.sidebar .nav-link.active {
  background-color: #3879be;
  color: #fff;
}

.sidebar .section-title {
  font-size: 0.8rem;
  color: #666;
  padding-left: 15px;
  margin-top: 15px;
  text-align: left;
}

.sidebar .section-title:first-of-type {
  margin-top: 10px;
}

.update-screen {
  background-color: #f8f9fa;
  color: #343a40;
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
  margin: 5px 0;
}

/* Stil für den KI-Assistenten-Button */
.ai-button {
  font-weight: bold;
  background-color: #E6E6FA;
  border-radius: 5px;
  padding: 8px;
  margin: 5px 0;
  transition: background-color 0.3s, color 0.3s;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ai-button i {
  margin-right: 8px;
}

.ai-button:hover {
  background-color: #3a4c93f8;
  color: #FFFFFF;
  text-decoration: none;
}

/* Zusätzliche Anpassungen für Responsivität */
@media (max-width: 576px) {
  /* Verstecken bestimmter Elemente auf mobilen Geräten */
  #fileInput,
  .dropzone,
  .btn-upload,
  .btn-ai,
  .mt-4 {
    display: none;
  }

  /* Anpassen der Schriftgröße in der Sidebar */
  .sidebar .nav-link {
    font-size: 0.8rem;
  }

  .sidebar .section-title {
    font-size: 0.7rem;
  }

  /* Schaltfläche zum Umschalten der Sidebar anpassen */
  .sidebar-toggle-btn {
    bottom: 15px;
    left: 15px;
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
}