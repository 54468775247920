/* Estilos para AIchat.module.css */

/* Contenedor general con ancho y alto fijos */
.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Ajusta el ancho según tus necesidades */
    height: 95vh; /* Ajusta el alto según tus necesidades */
    background-image: url('../assets/blurbackground.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    backdrop-filter: blur(10px);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 15px;
    overflow: hidden;
}

/* Caja de chat que ocupa todo el espacio disponible */
.chatbox {
    flex: 1;
    display: flex;
    flex-direction: column-reverse; /* Para que los mensajes aparezcan desde abajo */
    overflow-y: auto;
    padding: 10px;
    background: rgba(72, 90, 161, 0.373);
    border-radius: 10px;
}

/* Texto informativo */
.info-text {
    font-size: 12px;
    color: #666;
    margin: 10px 0;
    text-align: center;
}

/* Mensajes */
.message {
    display: flex;
    margin-bottom: 10px;
}

.message.user {
    justify-content: flex-end;
}

.message.ai {
    justify-content: flex-start;
}

.message-content {
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 1.4;
    word-wrap: break-word;
}

.message.user .message-content {
    background-color: #DCF8C6;
    color: #000;
    border-bottom-right-radius: 0;
}

.message.ai .message-content {
    background-color: #ecececc2;
    color: #000000;
    border-bottom-left-radius: 0;
    text-align: left;

}

/* Contenedor de entrada */
.input-container {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.input-container input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    outline: none;
}

.input-container input:focus {
    border-color: #0084FF;
}

.input-container button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #0084FF;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
}

.input-container button:hover {
    background-color: #007BFF;
}
